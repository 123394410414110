import "./Header.scss";
import SnapReadLogo from '../../assests/icons/compress.svg'
import { Link, useNavigate } from "react-router-dom";
import login from "../../assests/icons/login.svg";
import logoutIcon from "../../assests/icons/logout.svg";
import person from "../../assests/icons/person.svg";
import folder from "../../assests/icons/folder_copy.svg";
import share from "../../assests/icons/diagram-project.svg";
import Loading from "../../pages/Loading/Loading";
import { useAuth } from "../../Contexts/AuthContext";



export function UserIcon({login_status,userData, setOpenLogin}){
    const navigate = useNavigate();

    const {logout} = useAuth();

    const handleLogOut =()=>{
        logout();
        navigate('/');
    }
    if(login_status){

        return (
                <div className="user">
                    <div className="user__wrapper"> 
                        <img src={person} alt ="shared" className="user__person" />
                        <div className="user__name">{userData.username}</div>
                    </div>
                    <div className="user__wrapper"> 
                    <Link to={`/articles/${userData.id}`}><img src={folder} alt ="saved" className="user__icons"/></Link>
                      <Link to={`/shared/${userData.id}`} ><img src={share} alt ="shared" className="user__icons" /></Link>  
                        <button onClick={handleLogOut} className="user__icons"><img src={logoutIcon} alt ="logout"/></button>
                    </div>
                </div>
             )
    } else{
        return  <button onClick={()=>{setOpenLogin(true);}} className="user__wrapper user__wrapper--login ">
                    <img src={login} alt="login" className="user__icons user__icons--login"/>
                    <div className="user__loginTitle">Log in</div>
                </button>
    }
   
};


function Header({setOpenLogin}){

    const {isLoggedIn, userInfo, loading} = useAuth();

    if (loading) {
        return <Loading />;
    }

    return(
        <nav className="navbar">
            
            <div className="navbar__wrapper">
                    <Link className="navbar__link" to="/">
                        <img className="navbar__icon"
                             src={SnapReadLogo}
                             alt="SnapReadLogo"
                        />
                        <div className="navbar__header">SnapRead</div>
                    </Link> 

          
                    <UserIcon login_status={isLoggedIn} userData={userInfo} setOpenLogin={setOpenLogin}/>
      
            </div>

        </nav>
    )
}

export default Header;