import "./SharedArticles.scss";
import { useEffect, useState } from "react";
import { Link, useParams ,useNavigate} from "react-router-dom";
import axios from "axios";
import folder from "../../assests/icons/folder_copy.svg"
import login from "../../assests/icons/login.svg";
import logoutIcon from "../../assests/icons/logout.svg";
import person from "../../assests/icons/person.svg";
import share from "../../assests/icons/diagram-project.svg";
import Loading from "../Loading/Loading";
import { useAuth } from "../../Contexts/AuthContext";
import SnapReadLogo from '../../assests/icons/compress.svg';


const baseURL= process.env.REACT_APP_BASE_URL;


function dateConveter(date){
    const theDate = new Date(date);
    const day = String(theDate.getUTCDate()).padStart(2, '0');
    const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNamesShort[theDate.getUTCMonth()];
    const year = theDate.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}

function UserIconSummaryPage({login_status,userData, setOpenLogin}){
    const navigate = useNavigate();

    const {logout} = useAuth();

    const handleLogOut =()=>{
        logout();
        navigate('/summary');
    }
    if(login_status){

        return (
                <div className="userSummaryPage">
                    <div className="userSummaryPage__wrapper userSummaryPage__wrapper--profile"> 
                        <img src={person} alt ="shared" className="userSummaryPage__person" />
                        <div className="userSummaryPage__name">{userData.username}</div>
                    </div>
                    <div className="userSummaryPage__wrapper"> 
                        <Link to={`/articles/${userData.id}`}> <img src={folder} alt ="saved" className="userSummaryPage__icons"/></Link>
                        <img src={share} alt ="shared" className="userSummaryPage__icons" />
                        <button onClick={handleLogOut} className="userSummaryPage__icons">
                            <img src={logoutIcon} alt ="logout"/>
                        </button>
                    </div>
                </div>
             )
    } else{
        return  <button onClick={()=>{setOpenLogin(true);}} className="userSummaryPage__wrapper userSummaryPage__wrapper--login ">
                    <img src={login} alt="login" className="userSummaryPage__icons userSummaryPage__icons--login"/>
                    <div className="userSummaryPage__loginTitle">Log in</div>
                </button>
    }
   
};

function DesktopHeader({setOpenLogin}){

    const {isLoggedIn, userInfo, loading} = useAuth();


    if (loading) {
        return <Loading />;
    }

    return(
        <nav className="SummaryDesktopnavbar">
            
            <div className="SummaryDesktopnavbar__wrapper">
                    <Link className="SummaryDesktopnavbar__link" to="/">
                        <img className="SummaryDesktopnavbar__icon"
                             src={SnapReadLogo}
                             alt="SnapReadLogo"
                        />
                        <div className="SummaryDesktopnavbar__header">SnapRead</div>
                    </Link> 

          
                    <UserIconSummaryPage login_status={isLoggedIn} userData={userInfo} setOpenLogin={setOpenLogin}/>
      
            </div>

        </nav>
    )
}


function SharedArticles({setOpenLogin}){
    const {user_id} = useParams();
    const[sharedArticles,setSharedArticles]= useState([])

    useEffect(()=>{
        const fetchArticles = ()=>{
            const response = axios.get(`${baseURL}/shared/${user_id}`).then((response)=>{
                console.log("Shared Articles :", response.data)
                const {all_shared_data} = response.data;
                setSharedArticles(all_shared_data)
                return response.data;
            })
            .catch((error)=>{
                console.log(error)
            })
            return response;
        }   
        fetchArticles();
    },[user_id])
    return(
        <>  
        <div className="desktopLandscape">
        <DesktopHeader setOpenLogin={setOpenLogin} />
        <section className="sharedArticlesBody">
            <div className="saveArticlesBody__headerWrapper">
                <img src={share} alt="folder" className="saveArticlesBody__icon" />
                <h1 className="saveArticlesBody__header">
                    Shared History
                </h1>
            </div>
            
            {sharedArticles.map((article)=>{
                return(
                    <>
                       <div className="articleContainer" key={article.id}>

                        <div className="article__wrapper">
                        <div className="article__heading">
                            Article Title
                            </div>
                        <Link className="article__data article__data--articleTitle" to={`details/${article.id}`}><div>{article.article_name}</div></Link>
                        </div>

                        <div className="article__wrapper">
                            <div className="article__heading">Category</div>
                            <div className="article__data">{article.category}</div>
                        </div>

                        <div className="article__wrapper">
                            <div className="article__heading">Shared With</div>
                            <div className="article__data">{article.shared_with}</div>
                        </div>

                        <div className="article__wrapper">
                            <div className="article__heading">Shared Date</div>
                            <div className="article__data">{dateConveter(article.created_at)}</div>
                        </div>
                    </div>
                
                    </>
                )
               
            })}
            <div className="SharedtabletDekstopView">
            <div className="SharedtabletDekstopView__titles">
            <div className="SharedtabletDekstopView__heading">Title</div>
            {sharedArticles.map((article)=>{
                return(
                    <>
                       <div className="SharedtabletDekstopView__titles SharedtabletDekstopView__titles--data" key={article.id}>
                        <Link to={`details/${article.id}`}><div>{article.article_name}</div></Link>
                        </div>
                    </>
                )})}
            </div>
            <div className="SharedtabletDekstopView__category">
            <div className="SharedtabletDekstopView__heading">Category</div>
            {sharedArticles.map((article)=>{
            return(
                <>
                   <div className="SharedtabletDekstopView__category SharedtabletDekstopView__category--data">
                    {article.category}
                    </div>
                </>
            )})}
            </div>

            <div className="SharedtabletDekstopView__sharedWith">
            <div className="SharedtabletDekstopView__heading">Shared With</div>
            {sharedArticles.map((article)=>{
            return(
                <>
                   <div className="SharedtabletDekstopView__trend SharedtabletDekstopView__trend--data">
                    {article.shared_with}
                    </div>
                </>
            )})}
            </div>


            <div className="SharedtabletDekstopView__date">
            <div className="SharedtabletDekstopView__heading">Shared Date</div>
            {sharedArticles.map((article)=>{
            return(
                <>
                   <div className="SharedtabletDekstopView__topic SharedtabletDekstopView__topic--data">
                    {dateConveter(article.created_at)}
                    </div>
                </>
            )})}
            </div>


            </div>    
        </section>
        </div>
        </>
    )
}

export default SharedArticles;

