import { useNavigate } from "react-router-dom";
import axios from  "axios";
import { useState } from "react";
import Loading from "../Loading/Loading";
import { useAuth } from "../../Contexts/AuthContext";
import "./Login.scss";

const baseURL= process.env.REACT_APP_BASE_URL;
const loginURL= `${baseURL}/login`;

function Login({openLogin, setOpenLogin}){
    const navigate = useNavigate();
    const [isLoggedIn,setIsLoggedIn] = useState(false);
    const { fetchUserData } = useAuth();


    const redirectSignUp = ()=>{
        setOpenLogin(false);
        navigate("/signup");
    };
    const handleLogIn = async (e) =>{
        e.preventDefault();
        const {email,password} = e.target.elements;
        setIsLoggedIn("loading");

        try{
            const response = await axios
            .post(loginURL,{
                email:email.value,
                password:password.value
            })
          
            sessionStorage.setItem('authToken',response.data.token);
            
            //re-fetching the user data to update AuthContext immediately after login
            await fetchUserData();
            setIsLoggedIn(true);
            setOpenLogin(false);
            navigate("/");
            
        }catch(error){
            console.error(error);
            alert("Incorrect email or password");
            setIsLoggedIn(false);
            setOpenLogin(true);
        };
      }


    if (isLoggedIn==="loading") {
        return <Loading />;
    }

    if(!openLogin){
        return null;
    }

    

    return(

    <div className="modalBackground">
        <div className="overLay" />
        <div className="modalContainer">
            <button className="modalContainer__cancelBtn" onClick={()=> setOpenLogin(false)}>X</button>
            <div className="modalContainer__title">Login</div>
            <form className="login" onSubmit={handleLogIn}>
            <label  htmlFor="email" className="login__labels">
                Email:
            </label>
            <input className="login__field" id="email" tabIndex="0"
                    placeholder="Enter your email address"
                    type="email"
                    name="email"
            required/>

            <label  htmlFor="password" className="login__labels">
                Password:
            </label>
            <input   className="login__field" id="password" tabIndex="0"
                    placeholder="Enter your password"
                    type="password"
                    name="password"
            required/>

            <button type="submit" className="login__button">
                Login
            </button>            

            </form>
            <div className="login__redirectSignup"> Don't have an account?
                <button className="login__redirectSignup login__redirectSignup--link" onClick={redirectSignUp}> Sign up</button>
            </div>  
        </div>

    </div>

    )
  }

export default Login;