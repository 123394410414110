
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Mainpage from "./pages/MainPage/MainPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ResultPage from "./pages/ResultPage/ResultPage";
import SavedArticles from "./pages/SavedArticles/SavedArticles";
import SharedArticles from "./pages/SharedArticles/SharedArticles";


function App(){ 
  const [openLogin, setOpenLogin] = useState(false);
  

  return (
    <BrowserRouter>

        <Header setOpenLogin={setOpenLogin} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup setOpenLogin={setOpenLogin} openLogin={openLogin} />} />
          <Route path="/" element={<Mainpage setOpenLogin={setOpenLogin}/>}/>
          <Route path="/summary" element={<ResultPage setOpenLogin={setOpenLogin}/>}/>
          <Route path="/articles/:user_id" element={<SavedArticles setOpenLogin={setOpenLogin} />} />
          <Route path="/shared/:user_id" element={<SharedArticles setOpenLogin={setOpenLogin}/>}/>
          <Route path="/articles/:user_id/details/:articleID" element={<ResultPage setOpenLogin={setOpenLogin}/>}/>
          <Route path="/shared/:user_id/details/:articleID" element={<ResultPage setOpenLogin={setOpenLogin}/>}/>
        </Routes>
        {openLogin && <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />}
        <Footer />

    </BrowserRouter>
  );
}

export default App;
