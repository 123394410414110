
import article from "../../assests/icons/article.svg";
import Loading from "../Loading/Loading";
import { useAuth } from "../../Contexts/AuthContext";
import { Link } from "react-router-dom";
import SnapReadLogo from '../../assests/icons/compress.svg';
import "./SummaryLoading.scss";
import { UserIcon } from "../../components/Header/Header";



function DesktopHeader({setOpenLogin}){

    const {isLoggedIn, userInfo, loading} = useAuth();

    if (loading) {
        return <Loading />;
    }

    return(
        <nav className="Desktopnavbar">
           
            <div className="Desktopnavbar__wrapper">
                    <Link className="Desktopnavbar__link" to="/">
                        <img className="Desktopnavbar__icon"
                             src={SnapReadLogo}
                             alt="SnapReadLogo"
                        />
                        <div className="Desktopnavbar__header">SnapRead</div>
                    </Link> 

          
                    <UserIcon login_status={isLoggedIn} userData={userInfo} setOpenLogin={setOpenLogin}/>
      
            </div>

        </nav>
    )
}

function SummaryLoading({setOpenLogin}){
    return(
        <>
        <DesktopHeader setOpenLogin={setOpenLogin} />
        <div className="desktopContainerAnimate">
        <section className="mainAnimate" >
            <div className="mainAnimate__imageWrapperAnimate">
                <img src={article} className="mainAnimate__imageWrapperAnimate mainAnimate__imageWrapperAnimate--image" alt="articleImg" />
            </div>
        </section>
        </div>
        </>
    )
};

export default SummaryLoading;