import "./Modals.scss";

function Modals({type,handleFunction,showSaveModal,setShowSaveModal,showShareModal,setShowShareModal,email,handleEmail}){

    const closeSaveModal = () => {
        setShowSaveModal(false);
    };
    const closeShareModal = () => {
        setShowShareModal(false);
    };

    return(
        <>
{showSaveModal && type === "saveModal" && (
      <div className="modalBackground">
      <div className="overLay" />
      <div className="articleModalsContainer">
          <button className="articleModalsContainer__cancelBtn" onClick={closeSaveModal}>X</button>
          <div className="articleModalsContainer__title">Would you like to save this article?</div>
          <form className="save" onSubmit={handleFunction}>
          <button type="submit" className="save__button save__button--yes">
              Yes
          </button>    
          <button className="save__button save__button--no" type="button" onClick={closeSaveModal}>
              No
          </button>                
          </form>
      </div>
  </div>

)}

{showShareModal && type === "shareModal" && (
            <div className="modalBackground">
            <div className="overLay" />
            <div className="articleModalsContainer">
                <button className="articleModalsContainer__cancelBtn" onClick={closeShareModal}>X</button>
                <form className="share" onSubmit={handleFunction}>
                <label  htmlFor="email" className="share__labels">
                    Email:
                </label>
                <input className="share__field" id="email" tabIndex="0"
                        placeholder="Enter recipient's email address"
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleEmail} 
                        required
                />
                <div className="share__buttonContainer">
                    <button type="submit" className="share__button share__button--yes">
                        Share!
                    </button>  
                    <button className="share__button share__button--no" type="button" onClick={closeShareModal}>
                        Cancel
                    </button>    
                </div>          
    
                </form>

            </div>
    
        </div>
    
        )}
</>
)


}

export default Modals;