import React,{useState, useEffect,useContext, createContext} from "react";

import axios from "axios";

const AuthContext = createContext();

const baseURL= process.env.REACT_APP_BASE_URL;
const userAuthorizeAPI = `${baseURL}/profile`;

export function useAuth(){
    return useContext(AuthContext)

};


export function AuthProvider(props){

    const [isLoggedIn,setIsLoggedIn]= useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);

    const logout=()=>{
        sessionStorage.removeItem("authToken");
        setIsLoggedIn(false);
        setUserInfo({})
    };


    const fetchUserData = async()=>{

        const token = sessionStorage.getItem("authToken");

        if (token){

            try{
                const response = await axios.get(userAuthorizeAPI,{
                    headers:{
                        Authorization: `Bearer ${token}`,
                    }
                });
                setUserInfo(response.data);
                setIsLoggedIn(true);

            } catch(error){
                console.error(error);
                setIsLoggedIn(false);

            } finally {
                setLoading(false);
            }
        } else{
            setLoading(false);
        }

    }

    useEffect(()=>{
        fetchUserData();
    },[])

    const value = {
        userInfo,
        setUserInfo,
        isLoggedIn,
        setIsLoggedIn,
        loading, 
        setLoading,
        logout,
        fetchUserData
    }
    

    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    )
}

