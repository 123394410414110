import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import rocketLaunch from "../../assests/icons/rocket_launch.svg";
import SnapReadLogo from '../../assests/icons/compress.svg'
import error_icon from "../../assests/icons/error.svg";
import "./Signup.scss";

const baseURL= process.env.REACT_APP_BASE_URL;
const signupURL = `${baseURL}/signup`;

function ShowError({errorMessage, fieldInvalid}){
    if (fieldInvalid){
      return (
        <>
          <div className="signupForm__errorWrapper">
            <img className="signupForm__errorImage" src={error_icon} alt="errorImg" />
            <span className="signupForm__errorMessage">{errorMessage}</span>
          </div>
        </>
      )
    };
  };

function Signup({setOpenLogin, openLogin}){
    const navigate = useNavigate();

    const [fieldvalidity, setFieldStatus]= useState({});

    const validateForm = (formData) =>{
        const fieldErrors = {};
        let fieldsValid = true;
        const passwordValidator= /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    
        for (const [key,value] of Object.entries(formData)){
         if(!value){
           fieldsValid = false;
           fieldErrors[key] = "This field is required";
         } 
        else if(key === 'email' && (typeof value !== 'string'|| !value.includes('@') || !value.includes('.com'))){
          fieldErrors["email"] = "Email is not valid";
          fieldsValid =false;
         }
         else if(key==='password' && (typeof value !== 'string' || !passwordValidator.test(value))){
          fieldErrors['password'] = "Must be minimum eight characters, at least one letter and one number";
          fieldsValid =false;
         };
        }
        setFieldStatus(fieldErrors);
        return fieldsValid;
       };
   
    useEffect(()=>{ setOpenLogin(false);},[setOpenLogin])

    const handleSignup = (e) =>{
        e.preventDefault();
        const{fullName,email,username,password,passwordConfirm} = e.target.elements;
        const data= {
            fullName:fullName.value,
            email:email.value,
            username:username.value,
            password:password.value,
            passwordConfirm:passwordConfirm.value,
          }

          if(validateForm(data)){
            const postSignUpData = () =>{
                const signUpResponse = axios
                    .post(signupURL,{
                        name:fullName.value,
                        email:email.value,
                        username:username.value,
                        password:password.value,
                    })
                    .then((response)=>{
                        console.log(response.data)
                        return response.data
                    })
                    .catch((error)=>{
                        console.error(error)
                    })
                
                return signUpResponse;
            };
            postSignUpData();
            navigate("/");
        }else{
            console.log("form is not valid")
          }
        }

    return(
        
        <section className="mainBody">
            <nav className="navbarDesktop">
            
            <div className="nnavbarDesktop__wrapper">
                <Link className="navbarDesktop__link" to="/">
                    <img className="navbarDesktop__icon"
                         src={SnapReadLogo}
                         alt="SnapReadLogo"
                    />
                    <div className="navbarDesktop__header">SnapRead</div>
                </Link>
            </div>
           
            </nav>

        <section className="formConainer-Desktop"> 
        <div  className="mainBody__headerWrapper">
            <img  className="mainBody__headerIcon" src={rocketLaunch} alt="headerImg"/>
            <h1 className="mainBody__header">Sign up</h1>
        </div>
        
          <form className="signup" onSubmit={handleSignup}>
            <label  htmlFor="fullName" className="signup__labels">
                Full Name:
            </label>
            <input  id="fullName" tabIndex="0"
                    placeholder="Full Name"
                    type="text"
                    name="fullName"
                    className="signup__field signup__field--name"
                   
            />
            <ShowError fieldInvalid={fieldvalidity.fullName} errorMessage={fieldvalidity.fullName}/>

            <label  htmlFor="email" className="signup__labels">
                Email:
            </label>
            <input  id="email" tabIndex="0"
                    placeholder="Enter your email address"
                    type="email"
                    name="email"
                    className="signup__field signup__field--email"
                   
            />
            <ShowError fieldInvalid={fieldvalidity.email} errorMessage={fieldvalidity.email}/>

            <label  htmlFor="username" className="signup__labels">
                Username:
            </label>
            <input  id="username" tabIndex="0"
                    placeholder="Enter your username"
                    type="text"
                    name="username"
                    className="signup__field signup__field--username"
                   
            />
            <ShowError fieldInvalid={fieldvalidity.username} errorMessage={fieldvalidity.username}/>

            <label  htmlFor="password" className="signup__labels">
                Create Your Password:
            </label>
            <input  id="password" tabIndex="0"
                    placeholder="Enter your password"
                    type="password"
                    name="password"
                    className="signup__field signup__field--password"
            />
            <ShowError fieldInvalid={fieldvalidity.password} errorMessage={fieldvalidity.password}/>


            <label  htmlFor="passwordConfirm" className="signup__labels">
                Re-enter Your Password:
            </label>
            <input  id="passwordConfirm" tabIndex="0"
                    placeholder="Re-enter your password"
                    type="password"
                    name="passwordConfirm"
                    className="signup__field signup__field--confirm"
                   
            />
            <ShowError fieldInvalid={fieldvalidity.passwordConfirm} errorMessage={fieldvalidity.passwordConfirm}/>
            <button type="submit" className="signup__button">
                Join
            </button>       

            <div className="signup__redirectLogin"> Already have an account? 
                <button type="button" className="signup__redirectLogin--link" onClick={()=>{setOpenLogin(true);}}>Login</button>
            </div>     
           
          </form>
          </section>
      </section>

    )
  }

export default Signup;