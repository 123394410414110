
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import article from "../../assests/icons/article.svg"
import axios from "axios";
import "./MainPage.scss";
import Loading from "../Loading/Loading";
import { useAuth } from "../../Contexts/AuthContext";
import SnapReadLogo from '../../assests/icons/compress.svg';
import { UserIcon } from "../../components/Header/Header";
import SummaryLoading from "../SummaryLoading/SummaryLoading";
import error_icon from "../../assests/icons/error.svg";


const baseURL= process.env.REACT_APP_BASE_URL;
console.log("baseURL: ",baseURL);
const summarizeAPI = `${baseURL}/articles/summarize`;
console.log("summarizeAPI: ",summarizeAPI);


function ShowError({errorMessage, fieldInvalid}){
    if (fieldInvalid){
      return (
        <>
          <div className="summarizeForm__errorWrapper">
            <img className="summarizeForm__errorImage" src={error_icon} alt="errorImg" />
            <span className="summarizeForm__errorMessage">{errorMessage}</span>
          </div>
        </>
      )
    };
  };


function DesktopHeader({setOpenLogin}){

    const {isLoggedIn, userInfo, loading} = useAuth();

    if (loading) {
        return <Loading />;
    }

    return(
        <nav className="Desktopnavbar">
            
            <div className="Desktopnavbar__wrapper">
                    <Link className="Desktopnavbar__link" to="/">
                        <img className="Desktopnavbar__icon"
                             src={SnapReadLogo}
                             alt="SnapReadLogo"
                        />
                        <div className="Desktopnavbar__header">SnapRead</div>
                    </Link> 

          
                    <UserIcon login_status={isLoggedIn} userData={userInfo} setOpenLogin={setOpenLogin}/>
      
            </div>

        </nav>
    )
}


function Mainpage({setOpenLogin}){
    const navigate = useNavigate();
    const [fieldvalidity, setFieldStatus]= useState({});


    const [summarized,setSummary] = useState(false);
    const [isSubmitting,setSubmit]=useState(false);

    const validateForm = (formData) =>{
        const fieldErrors = {};
        let fieldsValid = true;
        const wordCount = (str)=>{
            const array = str.trim().split(/\s+/);
            return array.length;
        }
    
        for (const [key,value] of Object.entries(formData)){
         if(!value){
           fieldsValid = false;
           fieldErrors[key] = "This field is required";
         } 
        else if(key === 'link' && (typeof value !== 'string'|| !value.includes('.com'))){
          fieldErrors["link"] = "link is not valid";
          fieldsValid =false;
         }
         else if(key==='topic' && (typeof value !== 'string' || wordCount(value)>2 )){
          fieldErrors['topic'] = "Maximum in 2 words";
          fieldsValid =false;
         }
         else if (key === 'category' && value === "") {
            fieldErrors["category"] = "Please select a category";
            fieldsValid = false;
        };
        }
        setFieldStatus(fieldErrors);
        return fieldsValid;
       };


    const summarize = (e)=>{
        e.preventDefault();
        const{link,topic,category}= e.target.elements;

        const data = {
            link:link.value,
            topic:topic.value,
            category:category.value
        }

        if(validateForm(data)){
            setSubmit(true);
            setTimeout(()=>{ 
                setSummary("loading");
            },1000);
               
            const postArtilceData = async ()=>{
    
            try{
                const requestData = {
                    urL: link.value,
                    topic: topic.value,
                    category: category.value,
                };
                const summaryResponse = await axios.post(summarizeAPI,requestData)
    
                sessionStorage.setItem("mySummary", JSON.stringify(summaryResponse.data));
                setSummary(true);
                setSubmit(false);
                //removing the article id saved in session from the previous summary
                sessionStorage.removeItem("savedArticleId")
                navigate("/summary")
                return summaryResponse.data
            }catch(error){
                console.error("Error posting article data:", error);
                setSubmit(false);
            }}
            postArtilceData();






        }
     
        
    }
 

    if(summarized === "loading"){
        return <SummaryLoading />;
    };
    
    return(
        <><DesktopHeader setOpenLogin={setOpenLogin} />
        <div className="desktopContainer">
            {summarized === "loading" ? (
                <SummaryLoading />
            ):
            (
            <section className={`main ${isSubmitting ? "animate": ""}`}>
                <div className="main__imageWrapper">
                    <img src={article} className="main__imageWrapper main__imageWrapper--image" alt="articleImg" />
                </div>

                <form className="articleForm" onSubmit={summarize}>
                    <label htmlFor="link" className="articleForm__labels">
                        Article Link
                    </label>
                    <input id="link" tabIndex="0"
                        placeholder="Article Link"
                        type="link"
                        name="link"
                        className="articleForm__field articleForm__field--link" />
                        <ShowError fieldInvalid={fieldvalidity.link} errorMessage={fieldvalidity.link}/>

                    <label htmlFor="category" className="articleForm__labels">
                        category
                    </label>
                    <select id="category" name="category" className="articleForm__field articleForm__field--category">
                        <option disabled="" defaultValue="" className="articleForm__dropDownPlacehoder">Pick the category</option>
                        <option value="Business">Business</option>
                        <option value="finance">finance</option>
                        <option value="History">History</option>
                        <option value="Politics">Politics</option>
                        <option value="Pop Culture">Pop Culture</option>
                        <option value="Science">Science</option>
                        <option value="Society">Society</option>
                        <option value="Sports">Sports</option>
                        <option value="Tech">Tech</option>
                    </select>
                    <ShowError fieldInvalid={fieldvalidity.category} errorMessage={fieldvalidity.category}/>

                    <label htmlFor="topic" className="articleForm__labels">
                        Topic
                    </label>
                    <input id="topic" tabIndex="0"
                        placeholder="What's it about?"
                        type="text"
                        name="topic"
                        className="articleForm__field articleForm__field--topic" />
                        <ShowError fieldInvalid={fieldvalidity.topic} errorMessage={fieldvalidity.topic}/>

                    <button type="submit" className="articleForm__button">
                        SnapRead
                    </button>

                </form>

            </section>
            )
        }
        </div></>
    )
}

export default Mainpage;