import "./Footer.scss";
import SnapReadLogo from '../../assests/icons/compress.svg'
import facebook from "../../assests/icons/facebook-square.svg";
import instagram from "../../assests/icons/instagram.svg";
import x from "../../assests/icons/close.svg";
import email from "../../assests/icons/local_post_office.svg";

function Footer(){

    return(
        <section className="footer">
            <div className="footer__containers">
                <div className="footer__brand">
                    <img  className="icons__logo"src={SnapReadLogo} alt="SnapReadLogo"/>
                    <div className="footer__brand--name">SnapRead</div>
                </div>
                <div className="footer__contact">
                    <div  className="footer__contact--header">Stay In Touch With us</div>
                    <div className="footer__contact--email">
                        <img className="icons" src={email} alt="email"/>
                        <div  className="footer__contact--header">hello@snapread.com</div>
                    </div>
                    <div className="footer__contact--social">
                        <a href="https://www.facebook.com" rel="noopener noreferrer"><img className="icons" src={facebook} alt="facebook" /></a>
                        <a href="https://www.instagram.com" rel="noopener noreferrer"><img className="icons" src={instagram} alt="instagram" /></a>
                        <a href="https://www.x.com" rel="noopener noreferrer"><img className="icons" src={x} alt="x" /></a>
                    </div>
                </div>
            </div>
            <div  className="footer__containers--copyright"> 
            <hr/>
                2024 © All rights reserved-SnapRead
            </div>
        </section>
    )
}

export default Footer;