import "./SavedArticles.scss";
import { useEffect, useState } from "react";
import { Link, useParams ,useNavigate} from "react-router-dom";
import axios from "axios";
import folder from "../../assests/icons/folder_copy.svg"
import login from "../../assests/icons/login.svg";
import logoutIcon from "../../assests/icons/logout.svg";
import person from "../../assests/icons/person.svg";
import share from "../../assests/icons/diagram-project.svg";
import Loading from "../Loading/Loading";
import { useAuth } from "../../Contexts/AuthContext";
import SnapReadLogo from '../../assests/icons/compress.svg';


const baseURL= process.env.REACT_APP_BASE_URL;

function UserIconSummaryPage({login_status,userData, setOpenLogin}){
    const navigate = useNavigate();

    const {logout} = useAuth();

    const handleLogOut =()=>{
        logout();
        navigate('/summary');
    }
    if(login_status){

        return (
                <div className="userSummaryPage">
                    <div className="userSummaryPage__wrapper userSummaryPage__wrapper--profile"> 
                        <img src={person} alt ="shared" className="userSummaryPage__person" />
                        <div className="userSummaryPage__name">{userData.username}</div>
                    </div>
                    <div className="userSummaryPage__wrapper"> 
                        <Link to={`/articles/${userData.id}`}> <img src={folder} alt ="saved" className="userSummaryPage__icons"/></Link>
                        <Link to={`/shared/${userData.id}`}><img src={share} alt ="shared" className="userSummaryPage__icons" /></Link>
                        <button onClick={handleLogOut} className="userSummaryPage__icons"><img src={logoutIcon} alt ="logout"/></button>
                    </div>
                </div>
             )
    } else{
        return  <button onClick={()=>{setOpenLogin(true);}} className="userSummaryPage__wrapper userSummaryPage__wrapper--login ">
                    <img src={login} alt="login" className="userSummaryPage__icons userSummaryPage__icons--login"/>
                    <div className="userSummaryPage__loginTitle">Log in</div>
                </button>
    }
   
};

function DesktopHeader({setOpenLogin}){

    const {isLoggedIn, userInfo, loading} = useAuth();


    if (loading) {
        return <Loading />;
    }

    return(
        <nav className="SummaryDesktopnavbar">
            
            <div className="SummaryDesktopnavbar__wrapper">
                    <Link className="SummaryDesktopnavbar__link" to="/">
                        <img className="SummaryDesktopnavbar__icon"
                             src={SnapReadLogo}
                             alt="SnapReadLogo"
                        />
                        <div className="SummaryDesktopnavbar__header">SnapRead</div>
                    </Link> 

          
                    <UserIconSummaryPage login_status={isLoggedIn} userData={userInfo} setOpenLogin={setOpenLogin}/>
      
            </div>

        </nav>
    )
}


function SavedArticles({setOpenLogin}){
    const {user_id} = useParams();
    const[savedArticles,setSavedArticles]= useState([])

    useEffect(()=>{
        const fetchArticles = ()=>{
            const response = axios.get(`${baseURL}/articles/${user_id}`).then((response)=>{
                console.log("Saved Articles :", response.data)
                setSavedArticles(response.data)
                return response.data;
            })
            .catch((error)=>{
                console.log(error)
            })
            return response;
        }   
        fetchArticles();
        sessionStorage.removeItem("mySummary");
        sessionStorage.removeItem("savedArticleId");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  
        });
    },[user_id])
    return(
        <>  
        <div className="desktopLandscape">
        <DesktopHeader setOpenLogin={setOpenLogin} />
        <section className="saveArticlesBody">
            <div className="saveArticlesBody__headerWrapper">
                <img src={folder} alt="folder" className="saveArticlesBody__icon" />
                <h1 className="saveArticlesBody__header">
                    Saved Articles
                </h1>
            </div>
            
            {savedArticles.map((article)=>{
                return(
                    <>
                       <div className="articleContainer" key={article.id}>

                        <div className="article__wrapper">
                        <div className="article__heading">
                            Article Title
                            </div>
                        <Link className="article__data article__data--articleTitle" to={`details/${article.id}`}><div>{article.article_name}</div></Link>
                        </div>

                        <div className="article__wrapper">
                            <div className="article__heading">Category</div>
                            <div className="article__data">{article.category}</div>
                        </div>

                        <div className="article__wrapper">
                            <div className="article__heading">Google Trend</div>
                            <div className="article__data">{article.google_trend}</div>
                        </div>

                        <div className="article__wrapper">
                            <div className="article__heading">Artilce Topic</div>
                            <div className="article__data">{article.topic}</div>
                        </div>

                        <div className="article__wrapper">
                            <div className="article__heading">Source</div>
                            <div className="article__data">{article.source}</div>
                        </div>
                    </div>
                
                    </>
                )
               
            })}
            <div className="tabletDekstopView">
            <div className="tabletDekstopView__titles">
            <div className="tabletDekstopView__heading">Title</div>
            {savedArticles.map((article)=>{
                return(
                    <>
                       <div className="tabletDekstopView__titles tabletDekstopView__titles--data" key={article.id}>
                        <Link to={`details/${article.id}`}><div>{article.article_name}</div></Link>
                        </div>
                    </>
                )})}
            </div>
            <div className="tabletDekstopView__category">
            <div className="tabletDekstopView__heading">Category</div>
            {savedArticles.map((article)=>{
            return(
                <>
                   <div className="tabletDekstopView__category tabletDekstopView__category--data">
                    {article.category}
                    </div>
                </>
            )})}
            </div>

            <div className="tabletDekstopView__trend">
            <div className="tabletDekstopView__heading">Google Trend</div>
            {savedArticles.map((article)=>{
            return(
                <>
                   <div className="tabletDekstopView__trend tabletDekstopView__trend--data">
                    {article.google_trend}
                    </div>
                </>
            )})}
            </div>


            <div className="tabletDekstopView__topic">
            <div className="tabletDekstopView__heading">Google Topic</div>
            {savedArticles.map((article)=>{
            return(
                <>
                   <div className="tabletDekstopView__topic tabletDekstopView__topic--data">
                    {article.topic}
                    </div>
                </>
            )})}
            </div>

            <div className="tabletDekstopView__source">
            <div className="tabletDekstopView__heading">Source</div>
            {savedArticles.map((article)=>{
            return(
                <>
                   <div className="tabletDekstopView__source tabletDekstopView__source--data">
                    {article.topic}
                    </div>
                </>
            )})}
            </div>



            </div>    
        </section>
        </div>
        </>
    )
}

export default SavedArticles;

