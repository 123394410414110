import "./ResultPage.scss";
import youtube from "../../assests/icons/youtube.svg";
import trend from "../../assests/icons/arrow-trend-up.svg";
import chart from "../../assests/icons/chart.svg";
import sourceImage from"../../assests/icons/source.svg";
import { useAuth } from "../../Contexts/AuthContext";
import SnapReadLogo from '../../assests/icons/compress.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import login from "../../assests/icons/login.svg";
import logoutIcon from "../../assests/icons/logout.svg";
import person from "../../assests/icons/person.svg";
import folder from "../../assests/icons/folder_copy.svg";
import share from "../../assests/icons/diagram-project.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../Loading/Loading";
import SummaryLoading from "../SummaryLoading/SummaryLoading";
import sadface from "../../assests/icons/sadface.svg";
import Modals from "../../components/Modals/Modals";


const baseURL= process.env.REACT_APP_BASE_URL;

function UserIconSummaryPage({login_status,userData, setOpenLogin}){
    const navigate = useNavigate();

    const {logout} = useAuth();

    const handleLogOut =()=>{
        logout();
        navigate('/summary');
    }
    if(login_status){

        return (
                <div className="userSummaryPage">
                    <div className="userSummaryPage__wrapper userSummaryPage__wrapper--profile"> 
                        <img src={person} alt ="shared" className="userSummaryPage__person" />
                        <div className="userSummaryPage__name">{userData.username}</div>
                    </div>
                    <div className="userSummaryPage__wrapper"> 
                        <Link to={`/articles/${userData.id}`}> <img src={folder} alt ="saved" className="userSummaryPage__icons"/></Link>
                       <Link to={`/shared/${userData.id}`}><img src={share} alt ="shared" className="userSummaryPage__icons" /></Link>
                        <button onClick={handleLogOut} className="userSummaryPage__icons">
                            <img src={logoutIcon} alt ="logout"/>
                        </button>
                    </div>
                </div>
             )
    } else{
        return  <button onClick={()=>{setOpenLogin(true);}} className="userSummaryPage__wrapper userSummaryPage__wrapper--login ">
                    <img src={login} alt="login" className="userSummaryPage__icons userSummaryPage__icons--login"/>
                    <div className="userSummaryPage__loginTitle">Log in</div>
                </button>
    }
   
};

function DesktopHeader({setOpenLogin}){

    const {isLoggedIn, userInfo, loading} = useAuth();


    if (loading) {
        return <Loading />;
    }

    return(
        <nav className="SummaryDesktopnavbar">
            
            <div className="SummaryDesktopnavbar__wrapper">
                    <Link className="SummaryDesktopnavbar__link" to="/">
                        <img className="SummaryDesktopnavbar__icon"
                             src={SnapReadLogo}
                             alt="SnapReadLogo"
                        />
                        <div className="SummaryDesktopnavbar__header">SnapRead</div>
                    </Link> 

          
                    <UserIconSummaryPage login_status={isLoggedIn} userData={userInfo} setOpenLogin={setOpenLogin}/>
      
            </div>

        </nav>
    )
}


function ResultPage({setOpenLogin, login_status,userData}){
    const {isLoggedIn, userInfo} = useAuth();
    const[summaryData,setSummaryData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[articleIsSaved,setSave]=useState(false);
    const[showSaveModal, setShowSaveModal] = useState(false);
    const[showShareModal, setShowShareModal] = useState(false);
    const [email, setEmail] = useState(""); 
    const[savedArticleId,setArticleId]= useState(null);
    const {articleID} = useParams();

 
    const checkSavedArticle = async (user_id,article_id)=>{
        const findArticle = await axios.get(`${baseURL}/articles/${user_id}/${article_id}`)
        .then((response)=>{
           if(response.status === 200){
            setSave(true);
           }else {
            setSave(false);
        }
        })
        .catch((error)=>{
            console.log(error.response.status)
            setSave(false);
            
        })
        return findArticle

    }

    const getArticleDetails = async (user_id,article_id)=>{
        
        const findArticle = await axios.get(`${baseURL}/articles/${user_id}/${article_id}`)
        .then((response)=>{
            console.log(response.data.result);
            const{theArticle,youtubeVideos}= response.data.result;
            const formattedVideoNames = youtubeVideos.map(video =>({
                link:video.video_link,
                title: video.video_title,
                id: video.id 
            }))
            
            const{article_name,source,google_trend:google_rating,summary,url:urL,category,topic}=theArticle;
            setSummaryData({article_name,source,google_rating,summary,urL,category,topic,youtube_videos:formattedVideoNames});
            setSave(true);
           
            //saving in the session so in case of user refereshing the tab on this page it would presist their data 
            sessionStorage.setItem("mySummary", JSON.stringify({article_name,source,google_rating,summary,urL,category,topic,youtube_videos:formattedVideoNames}));
            return response            
         }).catch((error)=>{
            console.error("Errod fetching the requested article: ",error)
        })
        return findArticle;
    }
    

    useEffect(()=>{
        const fetchSummaryData = async () =>{
            try{
                const data = sessionStorage.getItem("mySummary");
                if(summaryData){
                    console.log("Data presisted, continue mounting.")
                } else if (!summaryData){
                    if(data){
                        setSummaryData(JSON.parse(data));
                    }
                    //this is the if statement for when the client gets redirected to this page from the saved articles page
                    if(articleID){
                        getArticleDetails(userInfo.id,articleID);
                        sessionStorage.setItem("savedArticleId",JSON.stringify(articleID))
                        
                    }
                } else{
                    console.log("no data stored in session to be rendered")
                }
            }catch(error){
                console.error("Error fetching data from session storage", error);
            } finally{
                setIsLoading(false);
            }
        }
       fetchSummaryData();
       window.scrollTo({
        top: 0,
        behavior: 'smooth'  
    });
    },[articleIsSaved,savedArticleId, articleID,summaryData, userInfo.id]);


    //if the article is already saved, set the article save state to true which helps with enabling the unsave button
    const articleId = JSON.parse(sessionStorage.getItem("savedArticleId"));

    console.log("savedArticleId from previous session: ", articleId);
    if(articleId && userInfo.id){
         checkSavedArticle(userInfo.id, articleId)
      ;} 

    if(isLoading){
        return <SummaryLoading />;
    }
    if(!summaryData){
        return(
                <> 
                <div className="noDataPage">
                <DesktopHeader setOpenLogin={setOpenLogin}/>
                <div className="noDataPage__main">

                    <div className="noDataPage__message">No Summary available to display</div>
                    <img className="noDataPage__icon" src={sadface} alt="sadFace" />
                    <Link className="noDataPage__link" to="/">Please click here to summarize a new article!</Link>
                </div>
                </div>
                </>
                ) 
    }

    //destructuring the retrieved summarized data from the state
    const {article_name,source,google_rating,summary,urL,category,topic,youtube_videos} = summaryData;


    const openSaveModal = () => {
        setShowSaveModal(true);
    };

    const closeSaveModal = () => {
        setShowSaveModal(false);
    };


    //function for handling the save request
    const handleSave = async (e)=>{
        e.preventDefault();
            try{
                const response = await axios.post(`${baseURL}/articles/save/${userInfo.id}`,
                {
                    article_name,
                    source,
                    google_rating,
                    summary,
                    urL,
                    category,
                    topic,
                    youtube_videos
                });
               
                const{article_id}=response.data;
                sessionStorage.setItem("savedArticleId", JSON.stringify(article_id));
                    
                setArticleId(article_id);
                setSave(true);
                closeSaveModal();
            }catch (error) {
                console.error("Error saving article:", error);
            }
    
        }


    const openShareModal = ()=>{
        setShowShareModal(true);
    }

    const closeShareModal =() =>{
        setShowShareModal(false);
    }
    const handleEmail= (e)=>{
        setEmail(e.target.value);
    }


    const handleShare = async (e)=>{
        e.preventDefault();

        try{
      
            if (articleIsSaved){
               const articleId= JSON.parse(sessionStorage.getItem("savedArticleId"));
               await axios.post(`${baseURL}/shared/${userInfo.id}/${articleId}`,
                {receiver:email})
                closeShareModal();
            
            } else{
                const savedResponse = await axios.post(`${baseURL}/articles/save/${userInfo.id}`,
                {
                    article_name,
                    source,
                    google_rating,
                    summary,
                    urL,
                    category,
                    topic,
                    youtube_videos
                });
                const{article_id}=savedResponse.data;
                setSave(true);
                await axios.post(`${baseURL}/shared/${userInfo.id}/${article_id}`,
                {receiver:email})
                closeShareModal();
            }

        }catch(error){
            console.error("Error sharing the article:", error);
        }

    };
    const handleDelete =async (e)=>{
        e.preventDefault();
        try{
            await axios.delete(`${baseURL}/articles/${articleId}`);
            setSave(false);
        }catch(error){
            console.error("Error deleting the article:", error);
        }
    }
   
    return (
        <>
        <div className="desktopLandscape">
        <DesktopHeader setOpenLogin={setOpenLogin} />
        <section className="summaryContainer">
            <div className="article"> 
            <h1  className="article__title">{article_name}</h1>
            <div  className="article__summary">{summary}</div>
            </div>
            <div className="article__breakdown">
                <div className="article__headingBox">
                    <img className="article__icon" src={chart} alt="chart"/>
                    <div className="article__title">Article Breakdown</div>
                </div>
                <div className="article__breakdownContent">
                <div className="article__popularityContainer">
                    <div className="article__popularity article__popularity--headingWrapper">
                    <img className="article__popularity article__popularity--trendImage" src={trend} alt="trend"/> 
                    <div className="article__popularity">Popularity:</div>
                    </div>
                    <div className="article__popularity article__popularity--rating">{google_rating}</div>
                </div>
                <div className="article__sourceContainer">
                    <div className="article__source article__source--headingWrapper">
                        <img className="article__source article__source--sourceImage" src={sourceImage} alt="source"/> 
                        <div className="article__source">Source:</div>
                        </div>
                        <a href={urL} className="article__source article__source--link">{source}</a>

                </div>  
                <div className="article__videos">
                    
                    <div className="article__videos  article__videos--headingWrapper">
                        <img className="article__videos article__videos--youtube" src={youtube} alt="youtube"/>
                        <div className="article__videos article__videos--header">Related videos:</div>
                    </div>
                {youtube_videos.map((youtube)=>{
                    return(
                        <ul>
                       
                       <li><a href={youtube.link} className="article__videos article__videos--links">{youtube.title}</a></li>
                        </ul>
                    )
                    
                })}
                </div>
                {isLoggedIn ?(
                     <div className="article__callToActions">
                     {articleIsSaved ?(
                         <button className="article__buttons article__buttons--save" onClick={handleDelete}>
                            Unsave
                        </button>
                     ):(<button className="article__buttons article__buttons--save" onClick={openSaveModal}>Save</button>
                    )}
                     
                     <button className="article__buttons article__buttons--share" onClick={openShareModal}>Share</button>
                 </div>
                ):<div></div>}
            </div>
            </div>          
            </section>
            </div>

            {showSaveModal && <Modals 
            type="saveModal" 
            handleFunction={handleSave} 
            showSaveModal={showSaveModal} 
            setShowSaveModal={setShowSaveModal}
            showShareModal={showShareModal} 
            setShowShareModal={setShowShareModal}
            />}

            {showShareModal && <Modals 
            type="shareModal"
             handleFunction={handleShare}
             showSaveModal={showSaveModal}
             setShowSaveModal={setShowSaveModal}
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            email={email}
            handleEmail={handleEmail}
            />}
            </>
    )
};

export default ResultPage;